const FolderFileData = (state = null, action) => {
    switch (action.type) {
        case 'HOLD_INFO_FOLDER_FILE':
            return action.payload
        case 'FOLDERFILE_CLEAR':
            return null
        default:
            return state
    }
}
export default FolderFileData