const Recyclebin = (state = null, action) => {
    switch (action.type) {
        case 'CONTENTLOCKER_RECYCLEBIN_SUCCESS':
            return action.payload?.res?.data
            case 'CONTENTLOCKER_DELETEFILE_SUCCESS':
            if (action?.payload?.status === 1) return { ...state, files: state?.files?.filter((user) => { return action?.payload?.data[0].id !== user?.id }) }
            case 'CONTENTLOCKER_PERMANENTDELETE_SUCCESS':
            if (action?.payload?.status === 1) return { ...state, files: state?.files?.filter((user) => { return action?.payload?.data[0].id !== user?.id }) }
        case "RECYCLEBIN_CLEAR":
            return null
        default:
            return state
    }
}
export default Recyclebin