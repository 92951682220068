const coparties = (state = null, action) => {
    switch (action.type) {
        case 'ADD_CO_PARTICIPANTS_SUCCESS':
            return action.payload.res.data
        case 'UPDATE_CO_PARTICIPANTS_SUCCESS':
            return action.payload.res.data
        case 'CLEAR_PARTIES':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default coparties    