const storage = (state = [], action) => {
    switch (action.type) {
        case 'STORAGE_GET_SUCCESS':
            return action.payload.res.data
        case 'CONTENTLOCKER_ADDFILE_SUCCESS':
            return { ...state, fileadd: action.payload.res.data }
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default storage