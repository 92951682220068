const verifierlogs = (state = [], action) => {
    switch (action?.type) {
        case 'VERIFIER_LOGS_SUCCESS':
            return action?.payload?.res?.data
        case 'CLEAR_SMART':
            return []
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default verifierlogs   