const contentid = (state = null, action) => {
    switch (action.type) {
        case 'CONTENTLOCKER_STREAM_FILE_SUCCESS':
            return action?.payload?.res?.data
        case 'SMART_CONTRACT_STREAM_FILE_SUCCESS':
            return action?.payload?.res?.data
        case 'RESET_CONTENTID':
            return null
        default:
            return state
    }
}
export default contentid    