const ContactList = (state = null, action) => {
    switch (action.type) {
        case 'CONTACT_LIST_SUCCESS':
            return action.payload.res.data?.data
        case 'RESET_ALL_STATES':
            return null
        // case 'CONTACT_LIST_SUCCESS':
        //     return action.payload.res.data?.data
        default:
            return state
    }
}
export default ContactList