const dashboardinvite = (state = null, action) => {
    switch (action.type) {
        case 'DASHBOARD_INVITES_SUCCESS':
            return action.payload?.res?.data
        case 'DASHBOARD_RESET':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default dashboardinvite