import { DraftStatus } from "../../../constant/contractconfig"

const masterlist = (state = null, action) => {
    switch (action.type) {
        case 'MASTERLIST_GET_SUCCESS':
            return action.payload.res.data
        case 'GET_EVIDENCE_LISTING_SUCCESS':
            return { ...state, evidence: action.payload.res.data.length }
        case 'UPLOAD_EVIDENCE_FILE_SUCCESS':
            return { ...state, evidence: state?.evidence + 1 }
        case 'CONTRACT_SEND_METADATA_SUCCESS':
            return { ...state, definition: { ...state.definition, contract: { ...state?.definition?.contract, draftStatus: action?.payload?.res?.data?.draftStatus } } }
        case 'CONTRACT_STOP_ENCRYPT_SUCCESS':
            return { ...state, definition: { ...state.definition, contract: { ...state?.definition?.contract, draftStatus: DraftStatus?.Sealed } } }
        case 'CONTRACT_EVIDENCE_UPLOAD_SUCCESS':
            console.log("redux", action?.payload?.res?.data)
            if (state?.immutable?.content === null) {
                return { ...state, immutable: { ...state?.immutable, content: [(action?.payload?.res?.data)] } }
            } else {
                return { ...state, immutable: { ...state?.immutable, content: state?.immutable?.content?.concat(action?.payload?.res?.data) } }
            }
        case 'DELETE_EVIDENCE_FILES_SUCCESS':
            return { ...state, immutable: { ...state?.immutable, content: state?.immutable?.content?.filter((e) => { return e?.contentID !== action?.payload?.data?.id }) } }
        case 'CLEAR_MASTERLIST':
            return null
        // case 'CLEAR_SMART':
        //     return null
        case "FIRST_CLEAR":
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default masterlist    