const notarygetlist = (state = [], action) => {
    switch (action.type) {
        case 'NOTARY_MANAGEMENT_LIST_SUCCESS':
            return action.payload.res.data
        case 'CLEAR_NOTARY':
            return []
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default notarygetlist