const sideMenuReduser = (state = [], action) => {
    switch (action.type) {
        case 'GET_SIDEMENU_SUCCESS':
            return action.payload.res.data
        case 'NOTARY_CUSTOM_TRIGGER_SUCCESS':
            return { ...state, acceptCustomTrigger: action?.payload?.accept }
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default sideMenuReduser