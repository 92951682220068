const AdminEmploylist = (state = null, action) => {
    switch (action.type) {
        case 'EMPLOYEE_LIST_GET_SUCCESS':
            return action.payload.res.data
        case 'NOTARY_FIRM_STATES_CLEAR':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default AdminEmploylist