const Triggerlist = (state = null, action) => {
    switch (action.type) {
        case 'TRIGGER_LIST_SUCCESS':
            return action?.payload?.res?.data
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Triggerlist    