const Adminapprovelist = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_APPROVAL_MANAGEMENT_LIST_SUCCESS':
            return action.payload?.res?.data
        case 'NOTARY_TRIGGER_LIST_SUCCESS':
            return action.payload?.res?.data
        case 'ADMIN_APPROVE_SUCCESS':
            if (state === null) {
                return state
            } else {
                return state?.filter((user) => { return action?.payload?.notaryId !== user?.notaryId })
            }
        case 'NOTARY_SMART_TRIGGER_APPROVE_SUCCESS':
            if (state === null) {
                return state
            } else {
                return state?.filter((user) => { return action?.payload?.contractID !== user?.id })
            }
        case 'ADMIN_REJECT_SUCCESS':
            if (state === null) {
                return state
            } else {
                return state?.filter((user) => { return action?.payload?.notaryId !== user?.notaryId })
            }
        case 'NOTARY_SMART_TRIGGER_REJECT_SUCCESS':
            if (state === null) {
                return state
            } else {
                return state?.filter((user) => { return action?.payload?.contractID !== user?.id })
            }
        case 'ADMIN_APPROVED_LIST_SUCCESS':
            return action.payload?.res?.data
        case 'NOTARY_TRIGGER_APPROVED_SUCCESS':
            return action.payload?.res?.data
        case 'ADMIN_REJECTED_LIST_SUCCESS':
            return action.payload?.res?.data
        case 'NOTARY_TRIGGER_REJECTED_SUCCESS':
            return action.payload?.res?.data
        case 'RESET_APPROVAL_STATES':
            return []
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default Adminapprovelist    