const EvidenceFile = (state = [], action) => {
    switch (action?.type) {
        case 'GET_EVIDENCE_LISTING_SUCCESS':
            return action?.payload?.res?.data
        case 'UPLOAD_EVIDENCE_FILE_SUCCESS':
            return state.concat(action?.payload?.res?.data?.file)
        // case 'DELETE_EVIDENCE_FILES_SUCCESS':
        //     return state.filter((e) => { return e?.contentID !== action?.payload?.data?.id })
        case 'CLEAR_SMART':
            return []
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default EvidenceFile   