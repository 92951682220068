// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import logger from 'redux-logger'
import { createStore, applyMiddleware, compose } from 'redux'
import ApiResponce from './ApiResponce'

// ** init middleware
const consoleView = localStorage.getItem("consoleView")

// let middleware
// consoleView === 'true' ? (middleware = [thunk, logger, createDebounce()]) : (middleware = [thunk, logger, createDebounce()])

// const middleware = [thunk, createDebounce()]
const middleware = process.env.REACT_APP_ENV === "production" ? [thunk, createDebounce()] : [thunk, logger, createDebounce()]


// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
// const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(ApiResponce, ...middleware)))

export { store }
