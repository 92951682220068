const ConsumerTrustCircle = (state = null, action) => {
    switch (action.type) {
        case 'TRUST_CIRCLE_LIST_SUCCESS':
            return action.payload.res?.data
        case 'UPDATETRUST_CIRCLEAPI_SUCCESS':
            return null
        // case 'ADD_TRUSTCIRCLE_SUCCESS':
        //     return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default ConsumerTrustCircle