import { combineReducers } from 'redux'
import LegalServiceList from './contractService/Contractlist'
import ContractlistCreate from './contractService/ContractlistCreate'
import Legalservicetoggle from './Legalservicetoggle'
import serviceandflow from './contractService/serviceandflow'
import security from './security'
import consumeractivity from './reports/consumeractivity'
import notaryfirmactivity from './reports/notaryfirmactivity'
import consumersummary from './reports/consumersummary'
import lockerSummary from './reports/lockerSummary'
import filevault from './reports/filevault'
import contractsummary from './reports/contractsummary'
const adminReducer = combineReducers({
    LegalServiceList,
    ContractlistCreate,
    Legalservicetoggle,
    serviceandflow,
    security,
    consumeractivity,
    notaryfirmactivity,
    consumersummary,
    lockerSummary,
    filevault,
    contractsummary
})

export default adminReducer
