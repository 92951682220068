const ContractlistCreate = (state = "", action) => {
    switch (action.type) {
        case 'CONTRACT_SERVICE_LIST_SUCCESS':
            return action.payload.res.data
        case 'emptyCreate':
            return ""
        default:
            return state
    }
}
export default ContractlistCreate