// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import adminMessagesEn from '../../@core/assets/data/locales/adminen.json'
import notaryMessagesEn from '../../@core/assets/data/locales/notaryen.json'
import adminMessagesFr from '../../@core/assets/data/locales/adminfr.json'
import notaryMessagesFr from '../../@core/assets/data/locales/notaryfr.json'
import adminMessagesDE from '../../@core/assets/data/locales/adminde.json'
import notaryMessagesDE from '../../@core/assets/data/locales/notaryde.json'
import toastMessageEn from '../../@core/assets/data/locales/toasterJson/en.json'
import toastMessageFr from '../../@core/assets/data/locales/toasterJson/fr.json'
import toastMessageDe from '../../@core/assets/data/locales/toasterJson/de.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'
import { useDispatch, useSelector } from 'react-redux'


// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn, ...adminMessagesEn, ...notaryMessagesEn, ...toastMessageEn },
  de: { ...messagesDe, ...userMessagesDe, ...adminMessagesFr, ...notaryMessagesFr, ...toastMessageFr },
  fr: { ...messagesFr, ...userMessagesFr, ...adminMessagesDE, ...notaryMessagesDE, ...toastMessageDe },
  pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const statePre = useSelector(state => state.auth.Otpvalidateuser)
  const twofa = useSelector(state => state.auth.passwordValidate?.status)
  const statereg = useSelector(state => state.auth.Registration)
  // ** States
  const [locale, setLocale] = useState('en')
  // const [messages, setMessages] = useState(menuMessages['en'])
  const [messages, setMessages] = useState(menuMessages['en'])


  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: "SET_LANG_DATA", payload: messages })
  }, [messages])

  useEffect(() => {
    if (localStorage.getItem("language")) {
      if (localStorage.getItem("language") === "1") {
        setMessages(menuMessages['en'])
        setLocale("en")
      } else if (localStorage.getItem("language") === "2") {
        setMessages(menuMessages['fr'])
        setLocale("fr")
      } else if (localStorage.getItem("language") === "3") {
        setMessages(menuMessages['de'])
        setLocale("de")
      }
    }
  }, [statePre, twofa, statereg])

  // console.log("locale", locale)
  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
