const security = (state = null, action) => {
    switch (action.type) {
        case 'SECURITY':
            return action.payload
        case 'RESET1':
            return null
        default:
            return state
    }
}
export default security