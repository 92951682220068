const CustomtemplateList = (state = [], action) => {
    switch (action.type) {
        case 'CUSTOMTEMPLATE_GETLIST_SUCCESS':
            return action.payload?.res?.data
        case 'CUSTOMTEMPLATE_ADDFILE_SUCCESS':
            return state.concat(action?.payload?.res?.data?.data)
        case 'CUSTOMTEMPLATE_DELETE_SUCCESS':
            // console.log("ttest", state)
            return state?.filter((user) => { return action?.payload?.id !== user?.id })
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default CustomtemplateList    