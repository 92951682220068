const verifyRegistration = (state = null, action) => {
    switch (action.type) {
        case 'REGISTRATIONS_SUCCESS':
            return action?.payload?.res
        case 'NOTARY_SELF_REGISTRATON_SUCCESS':
            return action.payload.res
            case 'RESET_LOGIN':
                return null
        default:
            return state
    }
}
export default verifyRegistration