const ContentLockerViewfile = (state = null, action) => {
    switch (action.type) {
        // case 'CONTENTLOCKER_LISTVIEW_SUCCESS':
        //     return action.payload?.res?.data
        case 'CONTRACT_FETCH_METADATA_SUCCESS':
            return action.payload?.res?.data
        case 'CLEAR_METADATA':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default ContentLockerViewfile