const FolderHoldInfo = (state = null, action) => {
    switch (action.type) {
        case 'FOLDERHOLD_INFO_SUCCESS':
            return action.payload
        // case 'CONTENTLOCKER_ADDFILE_SUCCESS':
        //     if (action.payload?.headerlist) return { ...state, fileData: [...state?.fileData, action.payload?.res?.data] }

        case 'CONTENTLOCKER_ADDFILE_SUCCESS':
            console.log("actions", action?.payload)
            if (action?.payload?.headerlist) {
                if (state?.fileData === null || state?.fileData === undefined) {
                    return { ...state, fileData: [action?.payload?.res?.data] }
                } else {
                    return { ...state, fileData: [...state?.fileData?.concat(action?.payload?.res?.data)] }
                }
            } else {
                return null
            }
        case 'FILE_MOVE_SUCCESS':
            // console.log(action)
            return { ...state, fileData: state?.fileData?.filter((user) => { return action?.payload?.id !== user?.id }) }
        // case 'FILE_MOVE_SUCCESS':
        //     console.log("actions", action?.payload?.res?.data)
        //     if (action?.payload?.location !== "") {
        //         if (state?.fileData === null) {
        //             return { ...state, fileData: [action?.payload?.res?.data] }
        //         } else {
        //             return { ...state, fileData: [...state?.fileData?.concat(action?.payload?.res?.data)] }
        //         }
        //     }
        case 'FOLDER_RENAME_SUCCESS':
            return { ...state, alias: action?.payload?.alias }

        case 'CONTENTLOCKER_DELETEFILE_SUCCESS':
            console.log(action)
            if (action?.payload?.data) return { ...state, fileData: state?.fileData?.filter((user) => { return action?.payload?.data[0].id !== user?.id }) }
        // case 'CONTENTLOCKER_PERMANENTDELETE_SUCCESS':
        //     // console.log(action)
        //     if (action?.payload?.data) return { ...state, folders: state?.folders?.filter((user) => { return action?.payload?.data[0].id !== user?.id }) }

        // if (action?.payload?.data[0]?.inBin === true) return { ...state, fileData: state.fileData.filter((user) => { return action.payload.data[0].id !== user.id }) } 
        case 'CONTENTLOCKER_MARKIMP_SUCCESS':
            // console.log("payload.id", payload.id)
            if (state !== null) return { ...state, fileData: state?.fileData?.map((e) => { return { ...e, mark: action?.payload?.id === e?.id ? action?.payload?.mark : e?.mark } }) }
        // return { ...state, fileData: state.fileData.map((e) => { return e }) }
        case 'RESET_ALL_STATES':
            return null
        case 'FOLDERHOLD_CLEAR':
            return null
        default:
            return state
    }
}
export default FolderHoldInfo