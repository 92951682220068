const consumersummary = (state = null, action) => {
    switch (action.type) {
        case 'REPORT_CONSUMER_SUMMARY_SUCCESS':
            return action.payload.res.data
        case 'RESET_REPORTS':
            return null
        default:
            return state
    }
}
export default consumersummary