const TemplateDownload = (state = null, action) => {
    switch (action.type) {
        case 'CUSTOMTEMPLATE_DOWNLOAD_SUCCESS':
            return action?.payload?.res?.data?.data
        case 'CLEAR_DOWNLOAD':
            return null
        case 'CLEAR_DOWNLOAD1':
            return null
        default:
            return state
    }
}
export default TemplateDownload