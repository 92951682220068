const Download = (state = null, action) => {
    switch (action.type) {
        case 'CONTENTLOCKER_LISTVIEW_SUCCESS':
            return action?.payload?.res?.data
        case 'CLEAR_DOWNLOAD':
            return null
        case 'FLUSH_DOCUMENT':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Download