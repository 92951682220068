const NotaryContactDetails = (state = null, action) => {
    switch (action.type) {
        case 'NOTARYCONTACT_DETAIL_SUCCESS':
            return action.payload
        case 'EMPLOY_RBAC_DELETE_SUCCESS':
            return null
        case 'EMPLOY_RBAC_UPDATE_SUCCESS':
            return action.payload.res.data
        // case 'EMPLOY_RBAC_ACTIVESTATUS_SUCCESS':
        //     return action.payload.res.data.data
        case 'EMPLOY_RBAC_ACTIVESTATUS_SUCCESS':
            return { ...state, activeStatus: action.payload.res.data?.activeStatus }
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default NotaryContactDetails