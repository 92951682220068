const LegalServiceList = (state = [], action) => {
    switch (action.type) {
        case 'LEGAL_SERVICE_LIST_SUCCESS':
            return action.payload.res.data.data
        // case 'LEGAL_SERVICE_TOGGLE_SUCCESS':
        //     return {...state, customTrigger:action.payload.res.data.data.customTrigger}
        default:
            return state
    }
}
export default LegalServiceList