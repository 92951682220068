const Notaryfirmdetails = (state = null, action) => {
    switch (action.type) {
        case 'NOTARYFIRM_DETAILS':
            return action.payload
        case 'ADMIN_FIRM_IMAGE_UPLOAD_SUCCESS':
            return { ...state, companyLogo: action.payload.res.data.Data }
        case 'FIRM_NOTARY_IMAGE_DELETE_SUCCESS':
            return { ...state, companyLogo: "" }
        case 'NOTARY_FIRM_STATES_CLEAR':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Notaryfirmdetails