const verifyEmail = (state = null, action) => {
    switch (action.type) {
        case 'REGISTRATION_EMAIL_SUCCESS':
            return action.payload.res
        case 'RESET_LOGIN':
            return null
        default:
            return state
    }
}
export default verifyEmail