const ContactLogs = (state = [], action) => {
    switch (action.type) {
        case 'CONTACT_LOGS_SUCCESS':
            return action.payload?.res?.data?.data
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default ContactLogs   