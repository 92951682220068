const FilePreview = (state = null, action) => {
    switch (action.type) {
        case 'CUSTOMTEMPLATE_PREVIEW_SUCCESS':
            return action?.payload
        case 'SMART_DOCUMENT':
            return action?.payload
        case 'CLEAR_PREVIEW':
            return null
        default:
            return state
    }
}
export default FilePreview