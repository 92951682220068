const security = (state = null, action) => {
    switch (action.type) {
        case 'SECURITY_TAB_INFO_SUCCESS':
            return action.payload.res
        case 'SECURITY_TAB_INFO_FAILED':
            return action?.msg?.err?.response
        case 'CLEAR_MASTERLIST':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default security    