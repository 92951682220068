const Uploadfiles = (state = [], action) => {
    switch (action?.type) {
        case 'FILE_STORE':
            return action?.payload
        case 'REMOVE_CONTRACT_FILE':
            return []
        case 'CLEAR_SMART':
            return []
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default Uploadfiles    