const TrustCircleAdd = (state = null, action) => {
    switch (action.type) {
        case 'ADD_TRUSTCIRCLE_SUCCESS':
            return action.payload?.res
        case 'TRUSTCIRCLE_RESET':
            return null
        case 'UPDATETRUST_CIRCLEAPI_SUCCESS':
            return 200
        default:
            return state
    }
}
export default TrustCircleAdd