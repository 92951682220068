const Notaryemploydetails = (state = null, action) => {
    switch (action.type) {
        case 'NOTARY_EMPLOY_DETAILS':
            return action.payload
        case 'NOTARYMANAGEMENT_STATES_CLEAR':
            return null
        case 'NOTARY_FIRM_STATES_CLEAR':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Notaryemploydetails