const MarkasImportantList = (state = null, action) => {
    switch (action.type) {
        case 'CONTENTLOCKER_MARKIMPORTANT_SUCCESS':
            return action.payload?.res?.data
        case 'CONTENTLOCKER_MARKIMP_SUCCESS':
            return {...state, files: state?.files?.filter((el) => { return action.payload.id !== el.id }) }
        case 'RESET_MARKIMPORTANT':
            return null
            
        default:
            return state
    }
}
export default MarkasImportantList