const Adminpaymentlist = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_PAYMENTS_LOGS_SUCCESS':
            return action.payload.res.data
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default Adminpaymentlist