const Publickey = (state = null, action) => {
    switch (action.type) {
        case 'ENCRYPT_PUBLIC_KEY_SUCCESS':
            return action.payload.res.data
        case 'RESET_PUBLIC':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Publickey    