const filemove = (state = null, action) => {
    switch (action.type) {
        case 'FILE_MOVE_SUCCESS':
            return action.payload?.res?.data
        case "RESET_ALL_STATES":
            return null
        default:
            return state
    }
}
export default filemove