export const Contract = Object.freeze({
    Draft: "Draft",
    Upload: "upload content",
    AwaitingforPayment: "Awaiting for Payment",
    SignaturePending: "Signature Pending",
    WaitingforNotaryRelease: "Waiting for Notary Release",
    WaitingforProxyRelease: "Waiting for Proxy Release",
    WaitingforVerifierRelease: "Waiting for Verifier Release",
    ContractSigned: "Signed",
    ContractCompleted: "Contract Completed",
    ContractExpired: "Contract Expired",
    ConsentTimeframeExpired: "Consent Timeframe Expired",
    NotaryApproved: "Notary Approved",
    NotaryApprovalPending: "Notary Approval Pending",
    Rejected: "Rejected",
    Contractvoid: "Voided"
})

export const DraftStatus = Object.freeze({
    Payment: "payment",
    Sealed: "sealed"
})

export const SignatureProvider = Object.freeze({
    Subskribo: "Subskribo",
    Itsme: "ItsMe",
    Connective: "Connective"
})

export const SignatureType = Object.freeze({
    Simple: "simple",
    Advanced: "advanced",
    Qualified: "qualified"
})

export const ContractFlowId = Object.freeze({
    Acceptance: 1001,     //1
    Consentual: 2001,     //2
    Notary: 3001,         //3
    Verifier: 3002,       //8
    Existing: 1005,       //9
    Nda: 1004,
    Quote: 1003,
    Terms: 1002
})
export const ContractFamily = Object.freeze({
    Acceptance: 1,     //1
    Consentual: 2,     //2
    Release: 3         //3
})

export const StandardTriggerId = Object.freeze({
    deathOfIndividual: 1,
    releaseOnSpecifiedDate: 9,
    lostContactWithIndividual: 10,
    bankruptcyOfCorporation: 3,
    bankruptcyOfIndividual: 4
})

export const InputBoxConst = Object.freeze({
    InputBox36: 32,
    Name: 16,
    InputCommon: 64,
    InputArea: 512,
    EditorMaxLength: 2500
})

export const mobileLength = 15