const Notarymanagementdetails = (state = null, action) => {
    switch (action.type) {
        case 'NOTARYMANAGEMENT_DETAILS':
            return action.payload
        case 'ADMIN_NOTARY_IMAGE_SUCCESS':
            return { ...state, companyLogo: action?.payload?.res?.data?.Data }
        case 'ADMIN_NOTARY_IMAGE_DELETE_SUCCESS':
            return { ...state, companyLogo: "" }
        case 'FIRM_NOTARY_IMAGE_UPLOAD_SUCCESS':
            return { ...state, companyLogo: action?.payload?.res?.data?.Data }
        case 'FIRM_NOTARY_IMAGE_DELETE_SUCCESS':
            return { ...state, companyLogo: "" }
        case 'NOTARYMANAGEMENT_STATES_CLEAR':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Notarymanagementdetails