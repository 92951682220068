const Notifications = (state = [], action) => {
    switch (action.type) {
        case 'NOTIFICATION_SUCCESS':
            if (action.payload.res.data !== null) {
                return action?.payload?.res?.data
            }
        // return action.payload.res.data

        case 'NOTIFICATION_SINGLECLEAR_SUCCESS':
            // const id = action?.payload?.id2
            return (state.filter(c => c.ID !== action?.payload?.id2))

        case 'NOTIFICATIONINVITE_SUCCESS':
            return (state.filter(c => c.ID !== action?.payload?.id3))

        case 'NOTIFICATION_CLEARALL_SUCCESS':
            return []
        case 'RESET_ALL_STATES':
            return []
        // case 'VAILDATE_PASS_Reset':
        //     return null
        default:
            return state
    }
}
export default Notifications