const passwordValidateReduser = (state = null, action) => {
    switch (action.type) {
        case 'VAILDATE_PASS_SUCCESS':
            return action.payload.res
        case 'VAILDATE_PASS_Reset':
            return null
        default:
            return state
    }
}
export default passwordValidateReduser