/*eslint-disable*/
import { Contract } from "../../../constant/contractconfig"

const smartcontractlist = (state = [], action) => {
    switch (action.type) {
        case 'SMART_CONTRACT_LIST_SUCCESS':
            return action.payload.res.data
        case 'SMART_CONTRACT_DELETE_SUCCESS':
            return { ...state, data: state?.data?.filter((e) => { return e?.id !== action?.payload?.id }) }
        case 'SMART_CONTRACT_VOID_SUCCESS':
            return {
                ...state, data: state?.data?.map((d) => {
                    if (action?.payload?.contractId === d?.id) {
                        return { ...d, status: Contract?.Contractvoid }
                    } else {
                        return d
                    }
                })
            }
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default smartcontractlist    