// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import master from './master'
import admin from './admin'
import SmartReducer from './Smartcontarct'
import Encryption from './Encryption'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  master,
  admin,
  SmartReducer,
  Encryption
  
})

export default rootReducer
