const Smartfile = (state = null, action) => {
    switch (action.type) {
        case 'SMART_CONTRACT_FILES_SUCCESS':
            return action?.payload?.res?.data
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Smartfile