const consumeractivity = (state = [], action) => {
    switch (action.type) {
        case 'REPORT_CONSUMER_ACTIVITY_SUCCESS':
            return action.payload.res.data
        case 'RESET_REPORTS':
            return []
        default:
            return state
    }
}
export default consumeractivity