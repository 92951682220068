const info = (state = null, action) => {
    switch (action.type) {
        // case 'CREATE_HOLD_INFO':
        //     if (state === null) {
        //         return action?.payload
        //     } else {
        //         return { ...state, services: action?.payload }
        //     }
        case 'CREATE_HOLD_PINFO':
            console.log("action?.payload", action?.payload)
            return { id: action?.payload?.id, name: action?.payload?.name, services: [] }
        case 'CREATE_HOLD_SINFO':
            return { ...state, services: action?.payload }
        case 'CLEAR_SMART':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default info    