const revoketoggle = (state = null, action) => {
    switch (action.type) {
        case 'REVOKE_CONTACT_SUCCESS':
            return action.payload?.res?.data
        case 'REVOKE_RESET':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default revoketoggle