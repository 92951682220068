const ContactDetails = (state = null, action) => {
    switch (action.type) {
        case 'CONTACT_DETAIL_SUCCESS':
            return action.payload
        case 'CLEARDETAILS':
            return null
        case 'RESET_ALL_STATES':
            return null
        // case 'CONTACT_FAV_TOGGLE_SUCCESS':
        //     return [...state, markFav action.payload.data.markFav]
        default:
            return state
    }
}
export default ContactDetails