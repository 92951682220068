const ContactListFavorite = (state = null, action) => {
    switch (action.type) {
        case 'CONTACT_FAV_TOGGLE_SUCCESS':
            return "updateSuccess"
            case 'CONTACT_LIST_SUCCESS':
            return ""
        default:
            return state
    }
}
export default ContactListFavorite