const extendsign = (state = null, action) => {
    switch (action.type) {
        case 'EXTEND_SIGN_VALIDITY_SUCCESS':
            return action.payload.res.data
        case 'CLEAR_EXTENT_SIGN':
            return null   
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default extendsign    