const buffer = [] // create an empty array which will be populated with all actions dispatched by Redux
const n = 1
export const ApiResponce = store => next => action => {
    buffer.push(action)
    if (action.payload?.err?.response?.status === 401 || action.payload?.res?.data?.code === 401 || action.msg?.response?.status === 401) {
        localStorage.clear()
        sessionStorage.clear()
        window.location.assign('/')
        // alert('Your session has expired')
    } else {
        if (buffer?.length > 20) {
            buffer?.splice(0, buffer?.length - 20)
        }
        return next(action)
    }
}

export default ApiResponce