import { combineReducers } from 'redux'
import Uploadfiles from './Uploadfiles'
import smartservices from './smartservices'
import smartcontractlist from './smartcontractlist'
import createsmart from './createsmart'
import masterlist from './masterlist'
import contractfileupload from './contractfileupload'
import coparties from './coparties'
import checkout from './checkout'
import esign from './esign'
import updateoverview from './updateoverview'
import usagehistory from './usagehistory'
import info from './info'
import Triggerlist from './Triggerlist'
import Addtrigger from './Addtrigger'
import addroles from './addroles'
import setsignorder from './setsignorder'
import notaryselect from './notaryselect'
import release from './release'
import dashboard from './dashboard'
import extendsign from './extentsign'
import notify from './notify'
import continueFree from './continueFree'
import rejectcontract from './rejectcontract'
import EvidenceFile from './EvidenceFile'
import verifierlogs from './verifierlogs'
import firmroles from './firmroles'
import suggestiontoggle from './suggestiontoggle'
import storedoc from './storedoc'
import security from './security'

const SmartReducer = combineReducers({
    Uploadfiles,
    smartservices,
    smartcontractlist,
    createsmart,
    masterlist,
    contractfileupload,
    coparties,
    checkout,
    esign,
    updateoverview,
    usagehistory,
    info,
    Triggerlist,
    Addtrigger,
    addroles,
    setsignorder,
    notaryselect,
    release,
    dashboard,
    extendsign,
    notify,
    continueFree,
    rejectcontract,
    EvidenceFile,
    verifierlogs,
    firmroles,
    suggestiontoggle,
    storedoc,
    security
})


export default SmartReducer
