const Notarystandardlist = (state = [], action) => {
    switch (action.type) {
        case 'NOTARY_STANDARD_TRIGGER_SUCCESS':
            return action.payload.res.data
        case 'STANDARD_TRIGGER_TOGGLE_SUCCESS':
            return state?.map((d) => {
                if (action?.payload?.id === d?.id) {
                    return { ...d, active: action?.payload?.active }
                } else {
                    return d
                }
            })
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default Notarystandardlist