const revoke = (state = [], action) => {
    switch (action.type) {
        case 'REVOKE_LIST_SUCCESS':
            return action.payload?.res?.data?.data
        case 'REVOKE_CONTACT_SUCCESS':
            return state?.filter((e) => { return e?.cid !== action?.payload?.cid })
        case 'ACCESS_TOGGLE_SUCCESS':
            return state.map((d) => {
                if (action?.payload?.cid === d.cid) {
                    return { ...d, fullViewAccess: action?.payload?.fullViewAccess }
                } else {
                    return d
                }
            })
        case 'REVOKE_ALL_CONTACT_SUCCESS':
            return null
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default revoke   