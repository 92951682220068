const ContentLockerList = (state = null, action) => {
    switch (action.type) {
        case 'CONTENTLOCKER_LIST_SUCCESS':
            return action.payload?.res?.data
        case 'CONTENTLOCKER_ADDFOLDER_SUCCESS':
            if (state?.folders === null) {
                return { ...state, folders: [action.payload?.res?.data] }
            } else {
                return { ...state, folders: [...state?.folders, action.payload?.res?.data] }
            }
        case "CONTENTLOCKER_MARKIMP_SUCCESS":
            return { ...state, files: state?.files?.map((e) => { return { ...e, mark: action?.payload?.id === e?.id ? action?.payload?.mark : e?.mark } }) }
        case 'CONTENTLOCKER_ADDFILE_SUCCESS':
            if (action?.payload?.headerlist === false) {
                if (state?.files === null) {
                    return { ...state, files: [action.payload?.res?.data] }
                } else {
                    return { ...state, files: [...state?.files, action.payload?.res?.data] }
                }
            }
        case 'FOLDER_RENAME_SUCCESS':
            return {
                files: state.files,
                folders: state?.folders?.map((d) => {
                    if (action?.payload?.id === d?.id) {
                        return { ...d, alias: action?.payload?.alias }
                    } else {
                        return d
                    }
                })
            }
        // return { ...d, lastMessage: { ...d.lastMessage, message_body: " ", created_at: action?.payload?.created_at } }
        // if (action.payload?.headerlist === false) 
        // return { ...state, files: [...state?.files, action.payload?.res?.data] }
        case 'CONTENTLOCKER_PERMANENTDELETE_SUCCESS':
            // console.log(action)
            if (action?.payload?.data) return { ...state, folders: state?.folders?.filter((user) => { return action?.payload?.data[0].id !== user?.id }) }

        case 'CONTENTLOCKER_DELETEFILE_SUCCESS':
            // console.log(action)
            if (action.payload?.data) return { ...state, files: state?.files?.filter((user) => { return action.payload.data[0].id !== user.id }) }

        case 'FILE_MOVE_SUCCESS':
            // console.log(action)
            if (action.payload?.location !== "") return { ...state, files: state?.files?.filter((user) => { return action.payload.id !== user.id }) }
        // case 'CONTENTLOCKER_DELETEFILE_SUCCESS':
        //     return state.concat(action?.payload?.res?.data)
        // case 'CONTENTLOCKER_DELETEFILE_SUCCESS':
        //     return {
        //         ...state, 
        //         files: files.filter((d) => {
        //             return d.id !== action.payload.id
        //         })
        //     }
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default ContentLockerList