const profileGet = (state = null, action) => {
    switch (action.type) {
        case 'PROFILE_GET_SUCCESS':
            return action.payload.res.data
        case 'PROFILEIMAGE_UPLOAD_SUCCESS':
            return { ...state, userInfo: { ...state?.userInfo, dp: action?.payload?.res?.data?.data } }
        case 'UPDATE_PROFILE_SUCCESS':
            return { ...state, userInfo: action.payload.res.data }
        case 'FIRM_PROFILE_UPDATE_SUCCESS':
            return { ...state, firmInfo: action.payload.res.data }
        case 'BILLING_ADDRESS_UPDATE_SUCCESS':
            return { ...state, billingInfo: action?.payload }
        case 'TWOFA_TOGGLE_SUCCESS':
            return { ...state, userInfo: { ...state?.userInfo, twofa: action?.payload?.status } }
        case 'HANDLE_EMAIL_CHANGE_SUCCESS':
            return { ...state, userInfo: { ...state?.userInfo, emailAddr: action?.payload?.newEmail } }
        case 'HANDLE_ADD_ALT_EMAIL_SUCCESS':
            return { ...state, userInfo: { ...state?.userInfo, altEmailAddr: action?.payload?.altNewEmail } }
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default profileGet