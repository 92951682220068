const consumerTrustcircledelete = (state = [], action) => {
    switch (action.type) {
        case 'TRUST_CIRCLE_PARTICIPANTS_DELETE_SUCCESS':
            return action.payload.res?.data?.data
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default consumerTrustcircledelete