const ContentMarkimp = (state = null, action) => {
    switch (action.type) {
        case 'CONTENTLOCKER_MARKIMP_SUCCESS':
            return action.payload?.res?.data
        case 'RESET_MARK_IMP':
            return null
        default:
            return state
    }
}
export default ContentMarkimp