// // **  Initial State
// const initialState = {
//   userData: {}
// }

// const authReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'LOGIN':
//       return {
//         ...state,
//         userData: action.data,
//         [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
//         [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
//       }
//     case 'LOGOUT':
//       const obj = { ...action }
//       delete obj.type
//       return { ...state, userData: {}, ...obj }
//     default:
//       return state
//   }
// }

// export default authReducer

import { combineReducers } from 'redux'
import passwordValidate from './passwordValidateReduser'
import sideMenu from './sideMenuReduser'
import Otpvalidateuser from './Optvalidateuser'
import loginHistory from './loginHistory'
import Notifications from './Notifications'
import user from './user'
import profileGet from './profileget'
import verifyEmail from './verifyEmail'
import verifyRegotp from './verifyRegotp'
import verifyRegistration from './verifyRegistration'
import Download from './Download'
import ForgetRegistration from './ForgetRegistration'
import NotificationLoad from './NotificationLoad'
import Registration from './Registration'
import prefrences from './prefrences'
import PaymentLink from './PaymentLink'
import userpaymentlist from './userpaymentlist'
import Adminpaymentlist from './Adminpaymentlist'
import otplogs from './otplogs'
import errorlogs from './errorlogs'
import storage from './storage'

const authReducer = combineReducers({
  passwordValidate,
  sideMenu,
  Otpvalidateuser,
  loginHistory,
  Notifications,
  user,
  profileGet,
  verifyEmail,
  verifyRegotp,
  verifyRegistration,
  Download,
  ForgetRegistration,
  NotificationLoad,
  Registration,
  prefrences,
  PaymentLink,
  userpaymentlist,
  Adminpaymentlist,
  otplogs,
  errorlogs,
  storage
})

export default authReducer
