const ForgetRegistration = (state = null, action) => {
    switch (action.type) {
        case 'FORGOT_PASSWORD_SUCCESS':
            return action.payload?.res?.status
        case 'RESET_LOGIN':
            return null
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default ForgetRegistration