import { combineReducers } from 'redux'
import ContactList from "../master/consumer/ContactList"
import ContactDetails from "../master/consumer/ContactDetails"
import ContactListFavorite from "../master/consumer/ContactListFavorite"
import NotaryContactList from "../master/notary/NotaryContactList"
import NotaryContactDetails from "../master/notary/NotaryContactDetails"
import AdminContactList from "../master/admin/AdminContactList"
import AdminContactDetails from "../master/admin/AdminContactDetails"
import TierTable from './admin/TierTable'
import KillSwitchstate from './admin/Killswitchstate'
import KillswitchstateUpdate from './admin/KillswitchstateUpdate'
import ConsumerTrustCircle from './consumer/ConsumerTrustCircle'
import ConsumerTrustCircledetails from './consumer/ConsumerTrustCircledetails'
import TrustCircleDelete from './consumer/TrustCircleDelete'
import TrustCircleAdd from './consumer/TrustCircleAdd'
import Employrbacdelete from './consumer/Employrbacdelete'
import LegalDetails from './notary/LegalDetails'
import contactDelete from './consumer/contactDelete'
import consumerTrustcircledelete from './consumer/consumerTrustcircledelete'
import ContactsAdd from './consumer/contactsAdd'
import ContentLockerList from './consumer/Contentlockerlist'
import ContentLockerViewfile from './consumer/ContentLockerViewFile'
import FolderHoldInfo from './consumer/Folderhold'
import Recyclebin from './consumer/Recyclebin'
import MarkasImportantList from './consumer/MarkimportantList'
import CustomtemplateList from "./admin/Customtemplatelist"
import ContactLogs from './consumer/ContactLogs'
import TemplateDownload from './consumer/TemplateDownload'
import FilePreview from './consumer/FilePreview'
import ContactListLoader from './consumer/ContantlistLoader'
import FavouriteToggle from './consumer/FavouriteToggle'
import UpdateTc from './consumer/UpdateTc'
import ContentAddFolder from './consumer/ContentAddFolder'
import ContentMarkimp from './consumer/ContentMarkimp'
import ContentLockerlistloader from './consumer/ContentLockerlistloader'
import FolderFileData from './consumer/FolderFileData'
import EmployRbacAdd from './notary/EmployRbacAdd'
import AdminContactLoad from './admin/AdminContactLoad'
import KillSwitchLogs from './admin/KillSwitchLogs'
import NotaryServiceList from './notary/NotaryServiceList'
import Language from './consumer/Language'
import UpdateCustomTemplate from './admin/UpdateCustomTemplate'
import TrustCircleLeave from './consumer/TrustCircleLeave'
import Trustacceptreject from './consumer/Trustacceptreject'
import Notaryfirmlist from './admin/Notaryfirmlist'
import toggleNotaryfirm from './admin/toggleNotaryfirm'
import AdminNotarycreate from './admin/AdminNotarycreate'
import Notaryfirmdetails from './admin/Notaryfirmdetails'
import notaryfirmupdate from './admin/notaryfirmupdate'
import AdminEmploylist from './AdminNotaryfirm/AdminEmploylist'
import Admindispatchdetails from './AdminNotaryfirm/Admindispatchdetails'
import linkednotarieslist from './AdminNotaryfirm/linkednotarieslist'
import notarygetlist from './AdminNotaryfirm/notarygetlist'
import notaryfirmnotarylist from './AdminNotaryfirm/notaryfirmnotarylist'
import notarycreate from './AdminNotaryfirm/notarycreate'
import Notarymanagementdetails from './AdminNotaryfirm/Notarymanagementdetails'
import notarymanagementemploylist from './AdminNotaryfirm/notarymanagementemploylist'
import Notarymanagementemploydetails from './AdminNotaryfirm/Notarymanagementemploydetails'
import NotarymanagementToggle from './AdminNotaryfirm/NotarymanagementToggle'
import Adminapprovelist from './Approvalmanagement/Adminapprovelist'
import Notaryemploydetails from './AdminNotaryfirm/Notaryemploydetails'
import approvaldatadispatch from './Approvalmanagement/approvaldatadispatch'
import insights from './AdminNotaryfirm/insights'
import approvaldashboard from './notary/approvaldashboard'
import revoke from './consumer/revoke'
import revoketoggle from './Approvalmanagement/revoketoggle'
import dashboardinvite from './Approvalmanagement/dashboardinvite'
import needattention from './consumer/needattention'
import Tierlist from './consumer/Tierlist'
import filemove from './consumer/filemove'
import Smartfile from './consumer/Smartfile'
import Notarystandardlist from './notary/Notarystandardlist'

const MasterReducer = combineReducers({
    ContactList,
    ContactDetails,
    ContactListFavorite,
    NotaryContactList,
    NotaryContactDetails,
    AdminContactList,
    AdminContactDetails,
    TierTable,
    KillSwitchstate,
    KillswitchstateUpdate,
    ConsumerTrustCircle,
    ConsumerTrustCircledetails,
    TrustCircleDelete,
    TrustCircleAdd,
    Employrbacdelete,
    LegalDetails,
    contactDelete,
    consumerTrustcircledelete,
    ContactsAdd,
    ContentLockerList,
    ContentLockerViewfile,
    FolderHoldInfo,
    Recyclebin,
    MarkasImportantList,
    CustomtemplateList,
    ContactLogs,
    TemplateDownload,
    FilePreview,
    ContactListLoader,
    FavouriteToggle,
    UpdateTc,
    ContentAddFolder,
    ContentMarkimp,
    ContentLockerlistloader,
    FolderFileData,
    EmployRbacAdd,
    AdminContactLoad,
    KillSwitchLogs,
    NotaryServiceList,
    Language,
    UpdateCustomTemplate,
    TrustCircleLeave,
    Trustacceptreject,
    Notaryfirmlist,
    toggleNotaryfirm,
    AdminNotarycreate,
    Notaryfirmdetails,
    notaryfirmupdate,
    AdminEmploylist,
    Admindispatchdetails,
    linkednotarieslist,
    notarygetlist,
    notaryfirmnotarylist,
    notarycreate,
    Notarymanagementdetails,
    notarymanagementemploylist,
    Notarymanagementemploydetails,
    NotarymanagementToggle,
    Adminapprovelist,
    Notaryemploydetails,
    approvaldatadispatch,
    insights,
    approvaldashboard,
    revoke,
    revoketoggle,
    dashboardinvite,
    needattention,
    Tierlist,
    filemove,
    Smartfile,
    Notarystandardlist
})

export default MasterReducer