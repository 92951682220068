const approvaldashboard = (state = [], action) => {
    switch (action.type) {
        case 'APPROVALS_DASHBOARD_SUCCESS':
            return action.payload?.res?.data
        case 'DASHBOARD_INVITES_SUCCESS':
            return (state.filter(c => c.emailAddr !== action?.payload?.id3))
        case 'RESET_ALL_STATES':
            return []
        default:
            return state
    }
}
export default approvaldashboard