const ConsumerTrustCircledetails = (state = null, action) => {
    switch (action.type) {
        case 'CONSUMER_TRUSTCIRCLE_DETAILS_SUCCESS':
            return action.payload
        case 'TRUSTCIRCLEACCEPTREJECT_SUCCESS':
            return {...state, status: action?.payload?.status }
        case 'CONTACT_TRUST_DELETE_SUCCESS':
            return null
        case 'CONTACT_TRUST_CIRCLE_LEAVE_SUCCESS':
            return null
        case 'RESET_ALL_STATES':
            return null
        // case 'TRUST_CIRCLE_PARTICIPANTS_DELETE_SUCCESS':
        //     return {state, }
        default:
            return state
    }
}
export default ConsumerTrustCircledetails