const ContactsAdd = (state = null, action) => {
    switch (action.type) {
        case 'CONTACTS_ADD_SUCCESS':
            return action.payload?.res
        case 'CONTACT_STATE_RESET':
            return null
        default:
            return state
    }
}
export default ContactsAdd