const storedoc = (state = null, action) => {
    switch (action.type) {
        case 'STORE_DOCUMENT':
            return action?.payload
        case 'CLEAR_MASTERLIST':
            return null 
        case 'CLEAR_PREVIEW':
            return null
            
        default:
            return state
    }
}
export default storedoc