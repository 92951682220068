const EmployRbacAdd = (state = null, action) => {
    switch (action.type) {
        case 'EMPLOY_ADD_CONTACT_SUCCESS':
            return action.payload?.res
        case 'EMPLOY_RESET':
            return null
        case 'UPDATETRUST_CIRCLEAPI_SUCCESS':
            return 200
        case 'RESET_ALL_STATES':
            return null
        default:
            return state
    }
}
export default EmployRbacAdd